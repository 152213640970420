import RoleEnum from "./role_enum";
import Routes from "./routes";
import Docto from "./docto";
import TokBox from "./tokbox";
import Medicare from "./medicare";
import EmergencyContent from "./emergency_access";
const bucketUrl = `https://docto-public-site.s3-ap-southeast-2.amazonaws.com`;
const EmailSettings = [
  { value: "reserved", label: "Reserved emails notifications" },
  { value: "expired", label: "Expired emails notifications" },
  // { value: "patient-waiting", label: "Patient waiting emails notifications" },
  // { value: "document-creation-copy", label: "Document creation copy emails" },
];
export {
  RoleEnum,
  Routes,
  bucketUrl,
  Docto,
  TokBox,
  Medicare,
  EmergencyContent,
  EmailSettings,
};
